import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faLaptopHouse, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { HTMLAttributes, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LinksMovidesk from './constante';
import './botoes.css';


interface MenuMovideskProps extends HTMLAttributes<HTMLElement> { }

const MenuMovidesk = ({ ...rest }: MenuMovideskProps) => {
  const integracaoMovidesk = (value: string) => window.open(value.toString(), '_blank');
  const navigate = useNavigate();
  const [statusBotoes, setStatusBotoes] = useState<boolean>(false)

  const toggleOpcoesDeChat = () => {
    setStatusBotoes(!statusBotoes);
  }

  return (
    <>
      <div {...rest} className='menu__movidesk'>
        <button className='layout__botoes' onClick={toggleOpcoesDeChat}>
          {
            <div className='botoes__chat'>
              <div>
                <label style={{ fontSize: '16px', color: '#FF1F1F', lineHeight: '19.1px' }}>CHAT</label>
                <hr />
                <div className='opcoes' onClick={() => integracaoMovidesk(LinksMovidesk.CHAT_PESSOAL)} >
                  <label style={{ fontSize: '16px' }}>Suporte Módulo Pessoal</label><br />
                </div>
                <div className='opcoes' onClick={() => integracaoMovidesk(LinksMovidesk.CHAT_RH)}>
                  <label style={{ fontSize: '16px' }}>Suporte Módulo RH</label>
                </div>
              </div>
            </div>
          }
          <FontAwesomeIcon className='layout__botoes__img' icon={faComments} />
        </button>
        <button className='layout__botoes' onClick={() => integracaoMovidesk(LinksMovidesk.CENTRA_DE_AJUDA)}>
          <label className='titulo' style={{ right: '285px' }}>Central de ajuda</label>
          <FontAwesomeIcon className='layout__botoes__img' icon={faLaptopHouse} />
        </button>
        <button className='layout__botoes' onClick={() => navigate('/ticket')}>
          <label className='titulo' style={{ right: '250px' }}>Enviar Sugestão</label>
          <FontAwesomeIcon className='layout__botoes__img' style={{ width: "23px" }} icon={faPaperPlane} />
        </button>
      </div>
    </>
  )
}

export default MenuMovidesk;