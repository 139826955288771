import Button from "../../../components/button/button";
import Spinner from "../../../components/spinner/spinner";
import { HtmlHTMLAttributes, useCallback, useContext, useEffect, useState } from "react";
import { Administrador, Comum, UsuarioInput, getCategoriaIndexByName } from "../../../services/UsuarioService";
import SessaoUsuarioContext from "../../../contexts/SessaoUsuarioContext";
import '../../shared/formulario.css'
import Feedback from "../../../components/feedback/feedback";
import useAppFeedback from "../../../hooks/use-app-feedback";

interface UsuarioFormProps extends HtmlHTMLAttributes<HTMLElement> {
  classes?: string,
  loading: boolean;
  titulo?: string;
  usuarioInput?: UsuarioInput;
  acao: {
    onClickAcao?: (value: UsuarioInput) => Promise<boolean>,
    texto: string
  };
  cancelarAcao?: {
    onClickCancelaAcao?: () => void
    texto: string
  }
}

const UsuarioForm = ({ loading, acao, cancelarAcao, classes, titulo, usuarioInput }: UsuarioFormProps) => {

  const { sessao } = useContext(SessaoUsuarioContext);

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [categoria, setCategoria] = useState("");
  const [usuario, setUsuario] = useState({});
  const { feedbacks, removerFeedback } = useAppFeedback();
  const [statusModulos, setStatusModulos] = useState<{ [key: string]: boolean }>({
    pessoal: false,
    rh: false
  });

  const atulizarStatusModulos = (status: boolean, nomeDoModulo: string) => {
    setStatusModulos(beforeValue => ({
      pessoal: (nomeDoModulo?.toLowerCase() === "pessoal" ? status : beforeValue?.pessoal),
      rh: (nomeDoModulo?.toLowerCase() === "rh" ? status : beforeValue?.rh)
    }));
  };

  const obtemCategriaDefault = () => {
    var categoriaEnum = getCategoriaIndexByName(categoria);
    return (categoriaEnum === Administrador ? "Administrador" : (categoriaEnum === Comum ? "Comum" : ""));
  }

  const clearForm = useCallback(() => {
    setEmail("");
    setNome("");
    setCategoria("");
    setStatusModulos({
      pessoal: false,
      rh: false,
    });
  }, []);

  useEffect(() => {
    const usuario = {
      id: usuarioInput?.id,
      nome: nome,
      email: email,
      categoria: categoria,
      statusModulos: statusModulos
    }
    setUsuario(usuario)
  }, [nome, email, categoria, statusModulos, usuarioInput])

  useEffect(() => {
    setEmail(usuarioInput?.email ?? "");
    setNome(usuarioInput?.nome ?? "");
    setCategoria(usuarioInput?.categoria ?? "");
    setStatusModulos({
      pessoal: usuarioInput?.statusModulos.pessoal || false,
      rh: usuarioInput?.statusModulos.rh || false,
    });
  }, [usuarioInput]);

  return (
    <>
      <div className={classes ?? 'formulario'}>
        <Feedback feedbacks={feedbacks} remover={removerFeedback} />
        {titulo && <h1>{titulo}</h1>}
        <label>Preencha os campos abaixo para criar um novo usuário</label>
        <div className='formulario__corpo'>
          <label htmlFor="form-usuario-nome">Nome:</label>
          <input
            id="form-usuario-nome"
            maxLength={30}
            className='formulario__corpo--input'
            onChange={x => setNome(x.target.value)}
            value={nome}></input>
          <label htmlFor="form-usuario-email">E-mail:</label>
          <input
            id="form-usuario-email"
            maxLength={150}
            className='formulario__corpo--input'
            readOnly={!!usuarioInput?.id}
            style={{color: usuarioInput?.id ? 'gray' : 'black'}}
            onChange={x => setEmail(x.target.value)}
            value={email}></input>
          <label htmlFor="form-usuario-categoria">Categoria:</label>
          <select id="form-usuario-categoria" className="formulario__corpo--select"
            onChange={x => setCategoria(x.target.value)}
            value={obtemCategriaDefault()}>
            <option value="" selected></option>
            <option value="Administrador">Administrador</option>
            <option value="Comum">Comum</option>
          </select>
          <fieldset style={{ borderRadius: '18px', border: '1px solid #ccc' }}>
            <legend>Módulos disponíveis:</legend>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {(() => {
                let randomValue = Math.round(Math.random() * 100000);
                return sessao?.modulosLiberados.map(modulo => (
                  <div key={(++randomValue)} style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <span>
                      <input id={`form-modulo-${randomValue}`} name={modulo.nome?.toLowerCase()} type="checkbox"
                        value={modulo.nome}
                        checked={statusModulos[modulo.nome?.toLowerCase()]}
                        onChange={x => atulizarStatusModulos(x.target.checked, x.target.value)} />
                      <label htmlFor={`form-modulo-${randomValue}`}> {modulo.nome.toUpperCase()}</label>
                    </span>
                  </div>
                ))
              })()}
            </div>
          </fieldset>
        </div>

        <Button
          classes='formulario__button'
          style={{ margin: 0 }}
          text={acao.texto}
          onClick={async () => acao.onClickAcao && await acao.onClickAcao(usuario as UsuarioInput) && clearForm()} />
        {
          cancelarAcao &&
          <Button
            classes='formulario__button'
            style={{ marginLeft: 8 }}
            text={cancelarAcao!.texto}
            onClick={() => cancelarAcao!.onClickCancelaAcao && cancelarAcao!.onClickCancelaAcao()} />
        }
      </div>
      {
        loading && <div className='loading'>
          <Spinner size={170} />
        </div>
      }
    </>
  );
}

export default UsuarioForm;