import { HTMLAttributes, ReactNode } from 'react'
import './modal.css'

interface ModalProps extends HTMLAttributes<HTMLElement> {
  isOpened: boolean,
  children: ReactNode
}
const Modal = ({ isOpened = false,  children, ...rest }: ModalProps) => {

  if (isOpened) {
    return (
      <div className='content'{...rest } >
        <div className="modal">
          {children}
        </div>
      </div>
    )
  }
  return (
    <></>
  )
}

export default Modal;