import { ReactNode } from 'react';
import './feedback.css';

export type TFeedback = { id: number; tipo: 'aviso' | 'sucesso' | 'erro'; mensagem: string | ReactNode}

interface FeedbackProps {
  feedbacks: TFeedback[];
  novaEstrutura?: boolean;
  remover?: (id: number) => void;
}

const Feedback = ({ feedbacks, novaEstrutura = false, remover }: FeedbackProps) => {

  const teclasParaFechar = ['Enter', 'Escape', 'Space'];

  const tratarKeyDown = (event: any, feedbackId: number) => {
    if (teclasParaFechar.some(tecla => event.code === tecla)) {
      remover!(feedbackId);
    }
  }

  return (
    !feedbacks.length
      ? <></>
      : (
        novaEstrutura === true ? <>{feedbacks[0].mensagem}</>
          :
          <div className="feedback-container">
            {feedbacks.map((feedback, index) => <div key={index} className={`feedback feedback-${feedback.tipo}`}>
              {feedback.mensagem}
              {remover &&
                <span className='fechar'
                  onClick={() => { remover!(feedback.id) }}
                  tabIndex={1}
                  onKeyDown={ev => tratarKeyDown(ev, feedback.id)}>&times;</span>}
            </div>)}
          </div >
      )
  );
}

export default Feedback;