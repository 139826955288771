import { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import AmbienteService from "../../services/AmbienteService";
import AppContext from "../../contexts/AppContext";
import "./info-nova-versao.css"

type EstadoValores = | 'inativo' | 'aguardandoAcaoDoUsuario' | 'consultando' | 'iniciandoAtualizacao' | 'erroNoProcessamento';

const InfoNovaVersao = ({ modulo }: { modulo?: string }) => {
  const { estaAtualizando, setAtualizando } = useContext(AppContext);
  const [novaVersaoAtualizavel, setNovaVersaoAtualizavel] = useState(false);
  const [estado, setEstado] = useState<EstadoValores>('inativo');

  const consultar = useCallback(async () => {
    if (!modulo) throw new Error("Nenhum módulo selecionado");

    if (estaAtualizando) {
      setEstado('inativo');
      return;
    }

    setEstado('consultando');

    const output = await AmbienteService.obterItemAAtualizar(modulo);
    switch (output.item) {
      case 'ambiente':
        setEstado('aguardandoAcaoDoUsuario');
        setNovaVersaoAtualizavel(true);
        break;
      case 'gerador_de_dados':
        setEstado('aguardandoAcaoDoUsuario');
        setNovaVersaoAtualizavel(false);
        break;
      default: setEstado('inativo');
    }
  }, [estaAtualizando, modulo]);

  useEffect(() => {
    (async () => { await consultar(); })();
  }, [consultar]);

  const atualizarAmbienteHandler = useCallback(async () => {
    if (!modulo) throw new Error("Nenhum módulo selecionado");

    setNovaVersaoAtualizavel(false);
    setEstado('iniciandoAtualizacao');
    try {
      await AmbienteService.atualizarVersaoDoAmbiente(modulo);
      setAtualizando(true);
    } catch (error) {
      setAtualizando(false);
      setEstado('inativo');
      throw error
    }
    setEstado('inativo');
  }, [setAtualizando,modulo]);

  const info = (mensagem: string | ReactElement) => <div className='info-nova-versao'>{mensagem}</div>

  return estaAtualizando ? <></> : <>
    {estado === 'iniciandoAtualizacao' && info('Iniciando atualização do ambiente...')}
    
    {estado === 'aguardandoAcaoDoUsuario'
      && novaVersaoAtualizavel
      && info(<>Tem uma nova versão do ambiente. <button onClick={atualizarAmbienteHandler} className='submit-button'>Atualizar</button></>)}
    
    {estado === 'aguardandoAcaoDoUsuario'
      && !novaVersaoAtualizavel
      && info(mensagensVersao[modulo as keyof typeof mensagensVersao].atualizarAgente)}
  </>;
}

const mensagensVersao = {
  pessoal: {
    atualizarAgente: 'Tem uma nova versão do ambiente. Atualize o sistema Fortes Pessoal e gere um novo arquivo de dados para o Fortes BI.'
  },
  rh: {
    atualizarAgente: 'Tem uma nova versão do ambiente. Atualize o sistema Fortes RH e envie novamente os dados para o Fortes BI.'
  }
}

export default InfoNovaVersao