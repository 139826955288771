import { useCallback, useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Ambiente } from '../models/Ambiente';
import SessaoUsuarioContext from '../contexts/SessaoUsuarioContext';
import AppContext from '../contexts/AppContext';

import AmbienteService from '../services/AmbienteService';
import UsuarioService, { UsuarioDoLicenciadoOutput } from '../services/UsuarioService';
import UsuarioLocalStorageService from '../services/UsuarioLocalStorageService';

import Menu from '../components/menu/menu'
import Spinner from '../components/spinner/spinner';
import Feedback from '../components/feedback/feedback';
import UsuarioLogado from '../components/usuario-logado/usuario-logado';

import Faturamento from '../pages/faturamento/Faturamento';
import AtualizarDados from '../pages/atualizar-dados/AtualizarDados';
import Painel from '../pages/painel/Painel';
import SelecaoDeLicenciado from '../pages/licenciado/selecao-de-licenciado';

import useSignOut from '../hooks/use-sign-out';
import useFeedback from '../hooks/use-feedback';
import { useToggleMenu } from '../components/menu/menuUtils';
import Historico from '../pages/historico/Historico';

import logoImg from './Logo.png';
import signOutImg from './signout.jpg';

import MenuMovidesk from '../components/menu-movidesk/botoes';
import Ticket from '../pages/ticket/ticket';
import TicketNovo from '../pages/ticket/ticketCriacao/ticketCriacao';
import ListaDePaineis from '../pages/lista-de-paineis/lista-de-paineis';
import Home from '../pages/home/Home';
import Usuario from '../pages/usuario/usuario';
import UsuarioCriacao from '../pages/usuario/usuario-criacao/usuario-criacao';
import Empresa from '../pages/empresa/empresa';
import EmpresaCriacao from '../pages/empresa/empresa-criacao/empresa-criacao';

import './App.css';
import '../pages/shared/content-container.css';

const App = () => {
  const navigate = useNavigate();
  const signOutCallback = useSignOut();
  const [sessao, setSessao] = useState<UsuarioDoLicenciadoOutput | null>();
  const [estaAtualizando, setAtualizando] = useState<boolean>(true);
  const [intervalAtualizando, setIntervalAtualizando] = useState<any>();
  const [ambiente, setAmbiente] = useState<Ambiente>({} as Ambiente);
  const [usuariosLicenciados, setUsuariosLicenciados] = useState<UsuarioDoLicenciadoOutput[]>([]);
  const { feedbacks, adicionarFeedbackDeErro, limparFeedbacks, removerFeedback } = useFeedback();
  const [adicionouTratamentoDeErro, setAdicionouTratamentoDeErro] = useState(false);
  const location = useLocation();
  const { isOpen = false, toggleMenu } = useToggleMenu();

  useEffect(limparFeedbacks, [location, limparFeedbacks]);

  const tratarErro = useCallback((eventoDeErro: any) => {
    eventoDeErro.preventDefault();
    limparFeedbacks();
    adicionarFeedbackDeErro((eventoDeErro.error || eventoDeErro.reason).message);
  }, [limparFeedbacks, adicionarFeedbackDeErro]);

  useEffect(() => {
    if (!adicionouTratamentoDeErro) {
      window.addEventListener("error", tratarErro);
      window.addEventListener("unhandledrejection", tratarErro);
      setAdicionouTratamentoDeErro(true);
    }
    return () => {
      window.removeEventListener("error", tratarErro);
      window.removeEventListener("unhandledrejection", tratarErro);
    }
  }, [adicionouTratamentoDeErro, setAdicionouTratamentoDeErro, tratarErro]);

  useEffect(() => {
    (async () => {
      if (!usuariosLicenciados.length) {
        var licenciados = await UsuarioService.obterInformacoesDoEmailDaSessao();
        if (!licenciados.length) {
          signOutCallback();
          localStorage.setItem("acesso-negado","Você não tem acesso a nenhum licenciado.");
          return;
        }
        setUsuariosLicenciados(licenciados);
      }
    })();
  }, [usuariosLicenciados, sessao, signOutCallback]);

  useEffect(() => {
    if (!sessao && usuariosLicenciados.length) {
      const usuarioLicenciadoLocalStorage = UsuarioLocalStorageService.obter();
      const temLicencaAtiva = usuarioLicenciadoLocalStorage?.temLicencaFortesPessoal
        || usuarioLicenciadoLocalStorage?.temLicencaFortesRh
        || usuarioLicenciadoLocalStorage?.temLicencaFortesFinanceiro;

      if (temLicencaAtiva
        && usuarioLicenciadoLocalStorage
        && usuariosLicenciados.some(u => u.licenciadoCnpj === usuarioLicenciadoLocalStorage.licenciadoCnpj)) {

        setSessao(usuarioLicenciadoLocalStorage);
      } else {
        navigate('/selecionar-licenciado');
      }
    }
  }, [sessao, navigate, usuariosLicenciados]);


  useEffect(() => {
    (async () => {
      if (!estaAtualizando && intervalAtualizando) {
        clearInterval(intervalAtualizando);
        setIntervalAtualizando(undefined);
      }
      if (sessao && !intervalAtualizando && estaAtualizando) {
        setAmbiente({ espacoDeTrabalhoId: "", paineis: []  });

        const output = await AmbienteService.obterAmbiente()
        setAtualizando(!output.processado);
        setAmbiente(output as Ambiente);

        if (!output.processado) {
          setIntervalAtualizando(
            setInterval(async () => {
              const output = await AmbienteService.obterAmbiente()
              setAtualizando(!output.processado);
              setAmbiente(output as Ambiente);
            }, 3000)
          )
        }
      }
    })();
  }, [estaAtualizando, intervalAtualizando, sessao])

  return (
    <SessaoUsuarioContext.Provider value={{ usuariosLicenciados, sessao, setSessao }}>
      <AppContext.Provider value={{ estaAtualizando, setAtualizando, ambiente, setAmbiente, feedback: useFeedback() }}>
        <div className="app">
          <header className="app-header">
            <div className="header-left">
              <div className="hamburger-icon">
                <Menu />
              </div>
              <div className="app-title">
                {
                  usuariosLicenciados && sessao
                    ? <Link to='/'> <img className="app-logo" src={logoImg} alt="Fortes Business Inteligence" /></Link>
                    : <img className="app-logo" src={logoImg} alt="Fortes Business Inteligence" />
                }
              </div>
            </div>
            {
              sessao && <div className="header-right">
                <MenuMovidesk id='menu-movidesk' />
                <UsuarioLogado />
                <button onClick={signOutCallback}><img src={signOutImg} width={25} alt="" /></button>
              </div>
            }
          </header>
          <div onClick={e => toggleMenu(isOpen)}>
            {sessao && estaAtualizando
              && <section className='app-info'>
                <div className='app-info-loading'>
                  <span>Seu ambiente está sendo atualizado... </span>
                  <Spinner size={14} />
                </div>
              </section>}
            <section className='app-content'>
              <Feedback feedbacks={feedbacks} remover={removerFeedback} />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/modulo/:modulo" element={<ListaDePaineis />} />
                <Route path="/selecionar-licenciado" element={<SelecaoDeLicenciado />} />
                <Route path="/usuario" element={<Usuario />} />
                <Route path="/usuario/novo" element={<UsuarioCriacao />} />
                <Route path="/empresa" element={<Empresa />} />
                <Route path="/empresa/novo" element={<EmpresaCriacao />} />
                <Route path="/faturamento" element={<Faturamento />} />
                <Route path="/atualizar" element={<AtualizarDados />} />
                <Route path="/historico" element={<Historico />} />
                <Route path="/ticket" element={<Ticket />} />
                <Route path="/ticket/novo" element={<TicketNovo />} />
                <Route path="/painel/:modulo/:parametroNomePainel" element={<Painel />} />
              </Routes>
            </section>
          </div>
        </div>
      </AppContext.Provider>
    </SessaoUsuarioContext.Provider>
  );
}

export default App;