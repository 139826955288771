import FBIHttp from "./FBIHttp";

export interface UsuarioDoLicenciadoOutput {
  id: string,
  nome: string;
  email: string;
  categoria: CategoriaDeUsuario;
  categoriaNome: string;
  licenciadoId: string;
  licenciadoCnpj: string;
  temLicencaFortesPessoal: boolean;
  temLicencaFortesRh: boolean;
  temLicencaFortesFinanceiro: boolean;
  modulosLiberados: Modulo[]
}

export interface UsuarioOutput {
  id: string,
  nome: string;
  email: string;
  categoria: any;
  modulos: Modulo[],
  statusModulos: StatusModulo
}

export interface UsuarioInput {
  id: string,
  nome: string;
  email: string;
  categoria: any,
  statusModulos: StatusModulo
}
export interface UsuarioDeleteInput {
  UsuarioId: string
}

enum CategoriaDeUsuario {
  Administrador,
  Comum
}

export interface Modulo {
  nome: string,
  status: boolean,
  modulo: string
}
export interface StatusModulo {
  pessoal: boolean,
  rh: boolean
}

export interface ObtencaoDeUsuariosOutput {
  sucesso: boolean,
  content: {
    usuarios: UsuarioOutput[]
  }
}

const categoriaIndexMap: Record<string, number> = {
  Administrador: 0,
  Comum: 1
};

export function getCategoriaIndexByName(name: string): number {
  return categoriaIndexMap[name];
}

export const Comum = getCategoriaIndexByName("Comum");
export const Administrador = getCategoriaIndexByName("Administrador");


class UsuarioService {
  public static obterInformacoesDoEmailDaSessao = async (): Promise<UsuarioDoLicenciadoOutput[]> => FBIHttp.get('/api/bi/Usuario/Info', false);
  public static obterUsuarios = async (): Promise<ObtencaoDeUsuariosOutput> => FBIHttp.get('/api/bi/Usuario/');
  public static salvarUsuario = async (input: UsuarioInput): Promise<UsuarioOutput> => FBIHttp.post('/api/bi/Usuario', input);
  public static deleteUsuario = async (input: UsuarioDeleteInput): Promise<UsuarioOutput> => FBIHttp.delete('/api/bi/Usuario/' + input.UsuarioId);
}

export default UsuarioService;
