import FBIHttp from "./FBIHttp";

interface AtualizacaoDeDadosOutput {
  erro?: string | null;
  nome?: string | null;
  data?: string | null;
  sucesso: boolean;
  nomeDoEspacoDeTrabalho?: string | null;
  mensagem?: string | null;
}

interface ObtencaoDeAmbienteOutput {
  mensagem?: string | null;
  processado?: boolean | null;
  espacoDeTrabalhoId?: string | null;
  paineis?: PainelOutput[] | null
  mensagemDeErro?:string | null;
}

interface PainelOutput {
  id?: string | null;
  nome?: string | null;
}

interface ObtencaoDeItemAAtualizarOutput {
  item: 'ambiente' | 'gerador_de_dados' | undefined
}

export interface ObtencaoDePainelOutput {
  sucesso?: boolean | null;
  erro?: string | null;
  chaveDeAcesso?: string | null;
  urlEmbutida?: string | null;
  espacoDeTrabalhoId?: string | null;
  painelId?: string | null;
}

class AmbienteService {
  private static readonly baseUrl = '/api/bi/Ambiente';

  public static atualizarAmbiente = async (file: File): Promise<AtualizacaoDeDadosOutput> => {
    const formData = new FormData();
    formData.append('file', file);

    return FBIHttp.post(this.baseUrl, formData);
  };

  public static atualizarVersaoDoAmbiente = async (modulo: string): Promise<AtualizacaoDeDadosOutput> => FBIHttp.post(`${this.baseUrl}/AtualizacaoDeVersao/${modulo}`);

  public static obterAmbiente = async (): Promise<ObtencaoDeAmbienteOutput> => FBIHttp.get(this.baseUrl);

  public static obterPainel = async (workspaceId: string, reportId: string): Promise<ObtencaoDePainelOutput> => FBIHttp.get(`${this.baseUrl}/Painel/${workspaceId}/${reportId}`);

  public static obterItemAAtualizar = async (modulo: string): Promise<ObtencaoDeItemAAtualizarOutput> => FBIHttp.get(`${this.baseUrl}/itemAAtualizar/${modulo}`);
}

export default AmbienteService;
