import { SelectHTMLAttributes } from 'react';
import './select.css';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement>{
  options: string[]
  onRowClick?: (value: any) => void;
}

const Select = ({ options, onRowClick }: SelectProps) => {

  return (
    <select
      className='select'
      disabled={options.length === 1 ? true : false}
      onChange={x => onRowClick && onRowClick(x.target.value)}>
      {options && options.length && options.map((option, index) => {
        return <option
          key={index}
          value={option}
          disabled={options.length < 1}
          selected={options.length === 1}
        >
          {option}
        </option>
      })}
    </select>
  );
}

export default Select;
