import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UsuarioService, { UsuarioInput, getCategoriaIndexByName } from '../../../services/UsuarioService';
import UsuarioForm from '../usuario-formulario/usuario-formulario';
import ErroDeNegocio from '../../../errors/ErroDeNegocio';
import useAppFeedback from '../../../hooks/use-app-feedback';

import './usuario-edicao.css'

interface UsuarioEdicaoProps {
  usuarioParaAtualizar: UsuarioInput;
  clickCancelar?: () => void;
}

const UsuarioEdicao = ({ usuarioParaAtualizar, clickCancelar }: UsuarioEdicaoProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { adicionarFeedbackDeErro, limparFeedbacks } = useAppFeedback();

  const atualizarUsuario = useCallback((async (usuario: UsuarioInput): Promise<boolean> => {
    limparFeedbacks();
    setLoading(true);

    try {
      usuario.categoria = getCategoriaIndexByName(usuario.categoria)
      await UsuarioService.salvarUsuario(usuario);
      navigate('/usuario', {state:{result: 'Usuario atualizado com sucesso'}});
      if (typeof clickCancelar === 'function') {
        clickCancelar();
      }
    } catch (error) {
      const message = (error as ErroDeNegocio)?.mensagem || (error as Error).message;
      adicionarFeedbackDeErro(message);
    }
    setLoading(false)
    return false;
  }), [adicionarFeedbackDeErro, limparFeedbacks, clickCancelar, navigate]);

  return (
    <>
      <UsuarioForm
        classes='usuario-edicao'
        usuarioInput={usuarioParaAtualizar}
        loading={loading}
        titulo={'Alterar Usuário'}
        acao={{
          onClickAcao: atualizarUsuario,
          texto: 'Salvar'
        }}
        cancelarAcao={{
          onClickCancelaAcao: clickCancelar,
          texto: "Cancelar"
        }} />
    </>
  );
}

export default UsuarioEdicao;

