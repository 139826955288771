import { ReactNode, useCallback, useState } from "react";
import { TFeedback } from "../components/feedback/feedback";

export type FeedbackStructure = {
  feedbacks: TFeedback[];
  adicionarFeedbackDeAviso: (mensagem: string) => void;
  adicionarFeedbackDeSucesso: (mensagem: string) => void;
  adicionarFeedbackDeErro: (mensagem: string) => void;
  adicionarFeedbackDeErroComTimeout: (mensagem: ReactNode) => void;
  limparFeedbacks: () => void;
  removerFeedback: (id: number) => void
}

const useFeedback = () => {
  const [feedbacks, setFeedbacks] = useState<TFeedback[]>([]);

  const criarFeedback = (tipo: 'aviso' | 'sucesso' | 'erro', mensagem: string | ReactNode) => ({ id: Date.now(), tipo, mensagem });

  const adicionar = (feedback: TFeedback) => setFeedbacks(fs => [...fs, feedback])

  const removerFeedback = useCallback((id: number) => setFeedbacks(fs => fs.filter(feedback => feedback.id !== id)), []);

  const adicionarComTimeout = useCallback((feedback: TFeedback) => {
    adicionar(feedback);
    setTimeout(() => { removerFeedback(feedback.id) }, 10000);
  }, [removerFeedback]);

  const adicionarFeedbackDeAviso = useCallback((mensagem: string) => { adicionarComTimeout(criarFeedback('aviso',mensagem)) }, [adicionarComTimeout]);

  const adicionarFeedbackDeSucesso = useCallback((mensagem: string) => { adicionarComTimeout(criarFeedback('sucesso', mensagem)) }, [adicionarComTimeout]);

  const adicionarFeedbackDeErroComTimeout = useCallback((mensagem: ReactNode) => { adicionarComTimeout(criarFeedback('erro', mensagem)) }, [adicionarComTimeout]);
  
  const adicionarFeedbackDeErro = useCallback((mensagem: string) => { adicionar(criarFeedback('erro', mensagem)) }, []);

  const limparFeedbacks = useCallback(() => {
    setFeedbacks(fs => {
      if (fs.length) {
        fs.splice(0, fs.length);
      }
      return fs
    })
  }, []);

  return { feedbacks, adicionarFeedbackDeAviso, adicionarFeedbackDeSucesso, adicionarFeedbackDeErro, limparFeedbacks, removerFeedback, adicionarFeedbackDeErroComTimeout };
}

export default useFeedback;