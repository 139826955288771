
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import UsuarioService, { UsuarioInput, getCategoriaIndexByName } from '../../../services/UsuarioService';
import Voltar from '../../../components/voltar/voltar';
import UsuarioForm from '../usuario-formulario/usuario-formulario';
import ErroDeNegocio from '../../../errors/ErroDeNegocio';
import useAppFeedback from '../../../hooks/use-app-feedback';

const UsuarioCriacao = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { adicionarFeedbackDeErro, limparFeedbacks } = useAppFeedback();

  const criaNovoUsuario = useCallback((async (usuario: UsuarioInput): Promise<boolean> => {
    limparFeedbacks();
    setLoading(true)

    let result = true;
    try {
      usuario.categoria = getCategoriaIndexByName(usuario.categoria)
      await UsuarioService.salvarUsuario(usuario);
      navigate('/usuario', { state:{result: 'Usuario criado com sucesso'}});
    } catch (error) {
      const message = (error as ErroDeNegocio)?.mensagem || (error as Error).message;
      adicionarFeedbackDeErro(message);
      result = false;
    }
    setLoading(false)
    return result;
  }), [adicionarFeedbackDeErro, limparFeedbacks, navigate])

  const voltar = useCallback(() => {
    limparFeedbacks();
    navigate('/usuario')
  }, [limparFeedbacks, navigate]);

  return (
    <>
      <h1 className="top-title"><Voltar caminho='usuario' />Novo Usuario</h1>
      <UsuarioForm
        loading={loading}
        acao={{
          onClickAcao: criaNovoUsuario,
          texto: "Salvar"
        }}
        cancelarAcao={{
          onClickCancelaAcao: voltar,
          texto: 'Voltar'
        }}
      />
    </>
  );
}
export default UsuarioCriacao;

