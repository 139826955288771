import Voltar from "../../../components/voltar/voltar";
import EmpresaService, { EmpresaInput } from "../../../services/EmpresaService";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmpresaForm from "../empresa-formulario/empresa-formulario";
import ErroDeNegocio from "../../../errors/ErroDeNegocio";
import useAppFeedback from "../../../hooks/use-app-feedback";

const EmpresaCriacao = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { adicionarFeedbackDeErro, limparFeedbacks } = useAppFeedback();

  const criarNovaEmpresa = useCallback((async (empresa: EmpresaInput): Promise<boolean> => {
    limparFeedbacks();
    setLoading(true)

    let result = true;
    try {
      await EmpresaService.salvarEmpresa(empresa);
      setLoading(false)
      navigate('/empresa', { state:{result: 'Empresa criada com sucesso'}});
    } catch (error) {
      const message = (error as ErroDeNegocio)?.mensagem || (error as Error).message;
      adicionarFeedbackDeErro(message);
      result = false;
      setLoading(false);
    }
    return result;
  }), [adicionarFeedbackDeErro, limparFeedbacks, navigate]);

  const voltar = useCallback(() => {
    limparFeedbacks();
    navigate('/empresa')
  }, [limparFeedbacks, navigate]);

  useEffect(() => {
    return () => limparFeedbacks();
  }, [limparFeedbacks]);

  return (
    <>
      <h1 className="top-title"><Voltar caminho="empresa" />Nova Empresa</h1>
      <EmpresaForm
        loading={loading}
        acao={{
          onClickAcao: criarNovaEmpresa,
          texto: 'Salvar'
        }}
        cancelarAcao={{
          onClickCancelaAcao: voltar,
          texto: 'Voltar'
        }} />
    </>
  )
}

export default EmpresaCriacao;